import React from 'react'
import Layout from '@components/Layout'
import { graphql } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { Article, Content } from '@global'

const CommunitiesTemplate = ({ data }) => {
  const seotitle = data.datoCmsCommunityLink.title + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  let facebookSection
  let websiteurlSection

  if (data.datoCmsCommunityLink.website) {
    websiteurlSection = (
      <span>
        <b>Website:</b>{' '}
        <a href={data.datoCmsCommunityLink.website} target="_blank" rel="noopener noreferrer">
          {data.datoCmsCommunityLink.website.replace(/^\/\/|^.*?:(\/\/)?/, '')}
        </a>
        <br />
      </span>
    )
  }

  if (data.datoCmsCommunityLink.facebook) {
    facebookSection = (
      <span>
        <b>Facebook:</b>{' '}
        <a href={`https://www.facebook.com/${data.datoCmsCommunityLink.facebook}`} target="_blank" rel="noopener noreferrer">
          /{data.datoCmsCommunityLink.facebook}
        </a>
        <br />
      </span>
    )
  }

  return (
    <Layout>
      <Article>
        <Seo title={seotitle} />
        <PageTitle title={data.datoCmsCommunityLink.title} />
        <Content
          dangerouslySetInnerHTML={{
            __html: data.datoCmsCommunityLink.contentNode.childMarkdownRemark.html,
          }}
        />

        {websiteurlSection}
        {facebookSection}
      </Article>
    </Layout>
  )
}

export const communityListingQueryGraph = graphql`
  query communityListingQuery($slug: String!) {
    datoCmsCommunityLink(slug: { eq: $slug }) {
      id
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      facebook
      website
    }
  }
`

export default CommunitiesTemplate
